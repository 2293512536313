<template>
    <div class="documents">
        <section class="container">
            <template v-if="files">
                <!-- <section class="container-grid">
                    <div class="grid-big">
                        <template v-for="(document,index, key) in files" :key="key">
                            <new50Document v-if="key == 0" :dataContent="document"></new50Document>
                        </template>
                    </div>
                    <section class="grid-small">
                        <template v-for="(document,index, key) in files" :key="key">
                            <documentSimpleText  v-if="key == 1" :dataContent="document"></documentSimpleText>
                        </template>
                     
                    </section>
                </section> -->
                <div class="grid-3">
                   
                    <template v-for="(document, index) in Object.values(files).slice(0,12)" :key="index">
                        <documentSimpleText :dataContent="document"></documentSimpleText>
                        
                    </template>

                </div>
                <paginator :pageActual="page" @changed="changePages" :totalItems="FilesMaxItems" @clicked="changePages"></paginator>
            </template>
            <div v-else class="container-noResult">
                <p class="title-dest-underline">No hay resultados</p>
            </div>
        </section>
    </div>
</template>

<script>
    // @ is an alias to /src
    import {
        mapGetters,
        mapActions,
        mapMutations
    } from 'vuex';
    import store from '@/store';
    //import documentVertical from "@/components/documents/document-view-vertical.vue";
    import documentSimpleText from "@/components/documents/document-view-simple-text.vue";
    import paginator from "@/components/paginator.vue";
    // import new50Document from "@/components/documents/new-50-document.vue";
    import utils from "@/utils/utils.js";
    export default {
        name: "documents",
        components: {
            //documentVertical,
            documentSimpleText,
            paginator,
            // new50Document
        },
        props: ['filterTime'],
        methods: {
            ...mapActions({
                loadFiles: 'contents/loadFiles'
            }),
            ...mapMutations({
                setPageFile: 'contents/setPageFiles',

            }),
            changePages(item) {
                this.setPageFile(item)
                this.loadFiles({
                    page: this.page,
                });
                utils.LoaderScrollToTop();
            }
        },
        computed: {
            ...mapGetters({
                getPagesFiles: 'contents/getPagesFiles',
                page: 'contents/getFilesViewPage',
                getFilesDocument: 'contents/getFilesDocument',
                getFilesReports: 'contents/getFilesReports',
                getFilesManifests: 'contents/getFilesManifests',
                getFilesMagazines: 'contents/getFilesMagazines'
            }),
            files: function () {
                return store.getters['contents/getFiles'](this.page)
            },

            FilesMaxItems() {
                return this.getPagesFiles();
            },
            
        },
        mounted() {
            this.loadFiles({ page: this.page });
        },
        watch: {
            filterTime: function (newVal, oldVal) {
                this.loadFiles({ page: 1 });
            }
        }
    };
</script>
<style>
    .-themes {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding-top: 60px;
        flex-direction: column;
    }

    .container-themes {
        margin-top: 30px;
        justify-content: center;
        align-items: flex-start;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }
</style>