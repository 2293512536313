<template lang="html">
    <section class="document-view-simple-text grid" v-if="dataContent">

        <div class="content-new">
            <div class="info-box">
                <!-- <span class="category">ÚLTIMA PUBLICACIÓN</span> -->
                <router-link :to="{
              name: 'page-entry',
              params: {
                typeContent: this.dataContent.type,
                slug: this.dataContent.slug
              }
            }" class="title " title="Ir a la informacion del documento">
                  <h2><Markdown v-if="this.dataContent.name_markdown" class="title" :source="this.dataContent.name_markdown" /></h2>
                    <!-- {{ dataContent.name }} -->
                </router-link>
                <router-link :to="{
        name: 'page-entry',
        params: {
          typeContent: this.typeContent,
          slug: this.dataContent.slug
        }
      }" class="content-new" title="Ir a la informacion del documento">
                    <p class="box-dates">
                        <span class="date-text">{{ currentDateTime(dataContent.publish_date) }}</span>

                        <span class="author" v-if="dataContent.interviewed">
                            {{ dataContent.interviewed }}</span>
                        <template v-for="theme in dataContent.themes" :key="theme.id">
                            <span class="category-tag">{{ theme.name }}</span>
                        </template>
                        <span class="category-tag" v-if="dataContent.file_type ">{{typeDocuments}}</span>
                        <span class="category-location" v-if="dataContent.zone">{{
                            dataContent.zone.name
                            }}</span>
                    </p>
                    <p class="w-100">
                        {{ cutText(dataContent.header , 90) }}
                    </p>
                </router-link>
                <div v-for="(archivo, index) in dataContent.file" :key="index">
                    <a :href="archivo.url" download="" target="_blank" class="url-download" title="Descargar archivo">Descargar archivo</a>
                </div>
            </div>

        </div>
    </section>
</template>

<script lang="js">
    import moment from 'moment'
    import Markdown from 'vue3-markdown-it';
    export default {
        name: 'document-view-simple-text',
        props: ['dataContent'],
        components: {
            Markdown
        },
        mounted() {

        },
        data() {
            return {

            }
        },
        methods: {
            currentDateTime(date) {
                return moment(date).locale('es').format('L')
            },
        },
        computed: {
            typeDocuments() {
                switch (this.dataContent.file_type) {
                    case 1:
                        return 'Informe'
                    case 2:
                        return 'Manifiesto'
                    case 3:
                        return 'Revista'
                    default:
                        return 'Documento'
                }

            },
            typeContent() {
                switch (this.dataContent.type) {
                    case 'entry':
                        return 'noticia'
                    case 'opinion':
                        return 'opinion'
                    case 'interview':
                        return 'entrevista'
                    case 'coverage':
                        return 'reportaje'
                    case 'event':
                        return 'evento'
                    case 'media':
                        return 'multimedia'
                    case 'file':
                        return 'file'
                    default:
                        return 'noticia'
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    @import "@/styles/colours.scss";

    .document-view-simple-text {
        cursor: pointer;
        /* border-bottom: 1px solid $blue; */

        &:last-of-type {
            border-bottom: 0;
            margin-bottom: 20px;
        }

        .content-new {
            text-decoration: none;
            display: flex;
            flex-wrap: wrap;
            border-radius: 0;
            padding-left: 0;
            margin-bottom: 0;

            .category {
                background: $blue;
                font-weight: 900;
                color: white;
                font-size: 16px;
                padding: 5px 15px;
                border-radius: 13px;
                z-index: 0;
                margin-bottom: 10px;
                display: inline-block;
            }

            .img-new {
                width: 100%;
                border-radius: 13px;
                height: 230px;
                object-fit: cover;
                object-position: center;
            }

            .info-box {
                width: 100%;
                padding: 20px 0;
                min-height: 290px;
                margin-bottom: 55px;
                position: relative;

                .url-download {
                    position: initial;
                    bottom: -55px;
                }
            }
        }

        .title {
            /* background-image: url(/img/icons/icon-download.svg); */
            background-repeat: no-repeat;
            background-size: 25px;
            background-position: right center;
            width: max-content;
            /* padding-right: 32px; */
            min-height: 25px;
            text-decoration: none;
            width: 100%;
        }

        .date-text {
            margin-top: 8px;
        }
    }

    @media screen and (max-width:845px) {
        .document-view-simple-text .content-new .info-box {
            min-height: auto;
        }
    }
</style>